import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import AliceCarousel from "react-alice-carousel"
import "react-alice-carousel/lib/alice-carousel.css"
import Style from "./Reviews.module.scss"

const Reviews = () => {
  const data = useStaticQuery(graphql`
    query {
      allGooglePlaces {
        nodes {
          result {
            user_ratings_total
            reviews {
              author_name
              author_url
              profile_photo_url
              rating
              text
            }
            url
            rating
          }
        }
      }
    }
  `)

  const handleOnDragStart = e => e.preventDefault()

  const responsive = {
    0: { items: 1 },
    768: { items: 1 },
    1200: { items: 1 },
  }

  const arrow = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`inline ${Style.shakeHorizontal}`}
      width="44"
      height="44"
      viewBox="0 0 24 24"
      strokeWidth="1"
      stroke="var(--primary)"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z"></path>
      <line x1="5" y1="12" x2="19" y2="12"></line>
      <line x1="15" y1="16" x2="19" y2="12"></line>
      <line x1="15" y1="8" x2="19" y2="12"></line>
    </svg>
  )

  const allReviews = data.allGooglePlaces.nodes[0].result.reviews.map(
    (review, i) => {
      var ratings = []

      var text = review.text
      if (text.length > 320) {
        text = review.text.substring(0, 320) + "[...]"
      }

      for (let index = 0; index < review.rating; index++) {
        let key = index
        ratings.push(
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="#ffb203"
            className="inline"
            key={key}
          >
            <path d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z" />
          </svg>
        )
      }

      return (
        <blockquote
          key={i}
          className="mt-8 focus:outline-none"
          style={{ cursor: "grab", userSelect: "none" }}
          onDragStart={handleOnDragStart}
          role="listbox"
          tabIndex={0}
        >
          <div className="max-w-3xl mx-auto text-center text-lg leading-9 font-medium text-gray-900">
            <div>{ratings}</div>
            <p>&ldquo;{text}&rdquo;</p>
          </div>
          <footer className="mt-8">
            <div className="md:flex md:items-center md:justify-center">
              <div className="md:flex-shrink-0">
                <img
                  className="mx-auto h-10 w-10 rounded-full"
                  src={review.profile_photo_url}
                  alt=""
                />
              </div>
              <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                <div className="text-base leading-6 font-medium text-gray-900">
                  {review.author_name}
                </div>
              </div>
            </div>
          </footer>
        </blockquote>
      )
    }
  )

  return (
    <section className="py-12 overflow-hidden md:py-20 lg:py-24">
      <div className="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <svg
          className="absolute top-full right-full transform translate-x-1/3 -translate-y-1/4 lg:translate-x-1/2 xl:-translate-y-1/2"
          width="404"
          height="404"
          fill="none"
          viewBox="0 0 404 404"
          role="img"
          aria-labelledby="svg-workcation"
        >
          <defs>
            <pattern
              id="ad119f34-7694-4c31-947f-5c9d249b21f3"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect
                x="0"
                y="0"
                width="4"
                height="4"
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width="404"
            height="404"
            fill="url(#ad119f34-7694-4c31-947f-5c9d249b21f3)"
          />
        </svg>

        <div className="relative">
          <AliceCarousel
            responsive={responsive}
            mouseTrackingEnabled={true}
            swipeDelta={1}
            buttonsDisabled={true}
            dotsDisabled={true}
            autoHeight={true}
            autoPlay={true}
            autoPlayInterval={5000}
          >
            {allReviews}
          </AliceCarousel>
          <div className="text-center">
            {arrow}
            <p>
              Gesamtbewertung {data.allGooglePlaces.nodes[0].result.rating} von
              5.
              <br />
              <a
                href={data.allGooglePlaces.nodes[0].result.url}
                target="_blank"
                rel="noopener noreferrer"
                className="hover:underline"
              >
                Alle{" "}
                <span className="text-primary">
                  {data.allGooglePlaces.nodes[0].result.user_ratings_total}
                </span>{" "}
                Bewertungen auf Google ansehen.
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Reviews
