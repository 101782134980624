import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

const Card = props => {
  return (
    <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
      <div className="flex-shrink-0">
        <Link to={props.link}>
          <Img fluid={props.image} />
        </Link>
      </div>
      <div className="flex-1 bg-white p-6 flex flex-col justify-between">
        <div className="flex-1">
          <p className="text-sm leading-5 font-medium text-indigo-600">
            <Link to={props.sublink} className="hover:underline">
              {props.subtitle}
            </Link>
          </p>
          <Link to={props.link} className="block">
            <h3 className="mt-2 text-xl leading-7 font-semibold text-gray-900">
              {props.title}
            </h3>
            <p className="mt-3 text-base leading-6 text-gray-700">
              {props.text}
            </p>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Card
